import { enqueueSnackbar } from "notistack";
import { COURSE } from "./constants";

export const validateMail = (email) => {
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
};

export const validatePassword = (pw) => {
  return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/g.test(pw);
};

export const noti = (text, variant = "success") => {
  enqueueSnackbar(text, {
    variant,
    anchorOrigin: { vertical: "bottom", horizontal: "right" },
    autoHideDuration: 3000,
  });
};

export const e500 = () => {
  noti("Lỗi không xác định", "error");
};

export const toParams = (params) => {
  return (
    "?" +
    Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&")
  );
};

export const toFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return formData;
};

export const onResult = (result) => {
  return result?.statusCode === 200;
};

export const formatText = (type, string) => {
  if (!string) return "";

  switch (type) {
    case "vnd":
      return `${+string}`.replace(/(.)(?=(\d{3})+$)/g, "$1.");
  }

  return string;
};

export const getLessonByType = (data) => {
  if (!data) return [[], []];

  const left = [];
  const right = [];

  data.forEach(({ type, name }) => {
    const index = name?.indexOf(":");

    let item;
    if (index === -1) item = ["", name.trim()];
    else item = [name.slice(0, index), name.substr(index + 1)];

    switch (type) {
      case COURSE.TYPE.BASIC:
        left.push(item);
        break;

      case COURSE.TYPE.ADVANCE:
        right.push(item);
        break;
    }
  });

  return [left, right];
};

export const getLessonByTypeFull = (data) => {
  if (!data) return [[], []];

  const left = [];
  const right = [];

  data.forEach((lesson) => {
    const { type, name } = lesson;
    const index = name?.indexOf(":");

    let item;
    if (index === -1) item = ["", name.trim()];
    else item = [name.slice(0, index), name.substr(index + 1)];

    switch (type) {
      case COURSE.TYPE.BASIC:
        left.push({ ...lesson, customName: item });
        break;

      case COURSE.TYPE.ADVANCE:
        right.push({ ...lesson, customName: item });
        break;
    }
  });
  return [left, right];
};

export const divide2 = (data) => {
  if (!data) return [[], []];
  const left = data.filter((e) => e);

  let num = Math.ceil(left.length / 2);
  num -= data.length % 2;
  const right = left.splice(left.length - num);

  return [left, right];
};

export const getLessonsAndTypes = (data) => {
  const types = [];
  const lessons = {};

  data.forEach((e) => {
    if (!lessons[e.type]) {
      types.push(e.type);
      lessons[e.type] = [];
    }
    lessons[e.type].push(e);
  });

  return { types, lessons };
};

export const baseKnowledgeFunc = (data) => {
  if (!Array.isArray(data)) {
    if (!data) return [];
    return chunkArray(data.split(","), 2).map(([percent, note]) => {
      return { percent, note };
    });
  } else {
    return data
      .reduce((result, { percent, note }) => {
        result.push(percent);
        result.push(note);
        return result;
      }, [])
      .join(",");
  }
};

function chunkArray(myArray, chunk_size) {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    tempArray.push(myChunk);
  }

  return tempArray;
}

export const getCourseOriginalPrice = (course) => {
  if (!course.fakePrice) return ""
  return formatText("vnd", course.fakePrice)
}

export const getCoursePrice = (course) => {
  const price = course.price || course.fakePrice
  return formatText("vnd", price)
}